import '../accesoDenegado/accesoDenegado.css';
import { useNavigate } from "react-router-dom";
import { BASE_URL_INTRANET } from '../../../utils/Constants';
import { Alert } from 'react-bootstrap';
import { Icono } from '../../../components/ui/Iconos';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import logoImage from '../../../assets/login/logo.png';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export const AccesoDenegado = () => {
    const navigate = useNavigate();

    return (
        <Container fluid className="container-fluid login-failled">
            <Row>
                <Col className='logo-col'>
                    <Image src={logoImage} rounded className='logo-login' />
                </Col>
                <Col>
                    <Card className='card-information'>
                        <Card.Body className='card-body-info'>
                            <Card.Title>Acceso denegado</Card.Title>
                            <Card.Text>
                                <p><strong>No posees los permisos necesarios para ingresar al portal de contrataciones.</strong></p>

                                <cite>Si crees que esto es un error, por favor contacta a la Gerencia de Personas.</cite>
                            </Card.Text>
                            <Button href={BASE_URL_INTRANET} variant="primary">Volver a Intranet</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};